// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/category/Category.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/category/Category.tsx");
  import.meta.hot.lastModified = "1704193608834.4607";
}
// REMIX HMR END

import { Box, Button, Card, CardBody, CardFooter, Heading, Image, Link, Stack, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { useFetcher } from "@remix-run/react";
import { useEffect, useState } from "react";
export const DealCard = props => {
  _s();
  const {
    category
  } = props;
  const [deals, setDeals] = useState(category.deals);
  const fetcher = useFetcher();
  function getDeals() {
    fetcher.submit({
      filter: {
        id: category.id
      }
    }, {
      method: "post",
      action: window.location.href,
      encType: "application/json"
    });
  }
  useEffect(() => {
    if (fetcher.data) {
      const data = fetcher.data;
      const items = data.deals;
      setDeals(items);
    }
  }, [fetcher.data]);
  useEffect(() => {
    if (!category.deals) {
      getDeals();
    }
  }, []);
  return <Box w={"100%"} maxW={"100%"}>
      <Wrap w={"100%"}>
        {deals && deals.map(deal => <WrapItem key={deal.id}>
              <VStack w={"100px"}>
                <Image src={deal.titleImageThumb ? deal.titleImageThumb : ""} fallbackSrc="/assets/placeholder-image.jpg" objectFit={"contain"} bgColor={"white"} borderRadius={"md"} boxSize={"100px"} p={1} loading="lazy"></Image>
                <Box isTruncated w={"100px"}>
                  {deal.title}
                </Box>
              </VStack>
            </WrapItem>)}
      </Wrap>
    </Box>;
};
_s(DealCard, "NbTs2MKk+eVPCUvu5wbORaFavI0=", false, function () {
  return [useFetcher];
});
_c = DealCard;
export const CategoryCard = props => {
  const {
    item
  } = props;
  return <>
      <Card direction={{
      base: "column",
      sm: "row"
    }} overflow="hidden" variant="outline">
        <Image objectFit="contain" maxW={{
        base: "100%",
        sm: "120px"
      }} src={item.brandLogo && item.brandLogo || "/assets/placeholder-image.jpg"} alt={item.title} bgColor={"white"} p="3" loading="lazy" fallbackSrc="/assets/placeholder-image.jpg" />

        <Stack>
          <CardBody>
            <Heading size="md" mb={4}>
              {item.title}
            </Heading>

            <DealCard category={item} />
          </CardBody>

          <CardFooter>
            <Link href={"/" + item.catURL}>
              <Button variant="solid" colorScheme="blue">
                Alle Deals
              </Button>
            </Link>
          </CardFooter>
        </Stack>
      </Card>
    </>;
};
_c2 = CategoryCard;
var _c, _c2;
$RefreshReg$(_c, "DealCard");
$RefreshReg$(_c2, "CategoryCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;
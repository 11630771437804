// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/$.$.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/$.$.tsx");
  import.meta.hot.lastModified = "1728030536059.7163";
}
// REMIX HMR END

import { Box, Center, HStack, Heading, Image, Tag, VStack } from "@chakra-ui/react";
import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { CategoryCard } from "~/components/category/Category";
import { DealList, DealListSmall, Pagination } from "~/components/dealList";
import { FrontpageHero } from "~/components/frontpage";
import { Navigation } from "~/components/navigation";
import { getCategories, multiSearchQuery, parseHitToCategory, parseHitToDealHit } from "~/utils/MeiliSearch";
export const headers = ({
  loaderHeaders
}) => ({
  "Cache-Control": loaderHeaders.get("Cache-Control") || "max-age=60, s-maxage=120"
});
async function getDealsByCat(id) {
  const dealFilter = `categories.id = '${id}' OR shops.id = '${id}' OR brands.id = '${id}' OR products.id = '${id}'`;
  const filter = `timestamp < ${Math.round(new Date().getTime() / 1000)} AND ${dealFilter}`;
  const hits = await multiSearchQuery([{
    limit: 10,
    offset: 0,
    filter,
    sort: ["timestamp:desc"]
  }]);
  return hits[0].hits.map(hit => parseHitToDealHit(hit));
}
export async function action({
  request,
  params
}) {
  const data = await request.json();
  const id = data.filter.id;
  const hits = await getDealsByCat(id);
  return json({
    deals: hits
  }, {
    status: 200
  });
}
export async function loader({
  request,
  params
}) {
  const path = params["*"];
  // console.log("params $.$", params);
  const pathArray = path?.split("/").filter(val => val != "") || [];
  const category = pathArray.length > 0 ? pathArray[0] : "kategorien";
  let page = 0;
  if (request.url) {
    const url = new URL(request.url);
    page = Number(url.searchParams.get("page") || 1) - 1;
  }
  const pageSize = 25;
  let dealFilter = "";
  let sort = ["timestamp:desc"];
  let selectedTab = 0;
  let tab = "neueste";
  let root = `/${category}`;
  let catObj = null;
  async function getCatFilter(category) {
    const catHits = await getCategories("", `catURL = ${category}`);
    const catResult = catHits[0];
    return catResult;
  }
  const queries = [];
  catObj = await getCatFilter(category);
  if (catObj) {
    dealFilter = `categories.id = '${catObj.id}' OR shops.id = '${catObj.id}' OR brands.id = '${catObj.id}' OR products.id = '${catObj.id}'`;
    const filter = `timestamp < ${Math.round(new Date().getTime() / 1000)} AND ${dealFilter} AND publish = true`;
    queries.push({
      limit: pageSize,
      offset: page * pageSize,
      filter,
      sort: sort
    });
    queries.push({
      sort: ["hotness:desc"],
      filter,
      limit: 5,
      offset: 0
    });
  }
  let mainCatgories, hotMainCategories;
  switch (category) {
    case "haupt-kategorien":
      mainCatgories = `type = 'main-category'`;
      hotMainCategories = ``;
      break;
    case "kategorien":
      mainCatgories = `type = 'category'`;
      hotMainCategories = `categories.id IS NOT EMPTY`;
      break;
    case "shops":
      mainCatgories = `type = 'shop'`;
      hotMainCategories = `shops.id IS NOT EMPTY`;
      break;
    case "marken":
      mainCatgories = `type = 'brand'`;
      hotMainCategories = `brands.id IS NOT EMPTY`;
      break;
    case "produkte":
      mainCatgories = `type = 'product'`;
      hotMainCategories = `products.id IS NOT EMPTY`;
      break;
    default:
      break;
  }
  if (mainCatgories) {
    queries.pop();
    queries.push({
      sort: ["hotness:desc"],
      filter: hotMainCategories,
      limit: 5,
      offset: 0
    });
    queries.push({
      sort: ["dealCount:desc"],
      filter: mainCatgories,
      limit: 10,
      offset: 0,
      indexUid: "category"
    });
  }
  const hits = await multiSearchQuery(queries);
  let categories;
  if (mainCatgories && hits[2] && hits[2].hits) {
    const catHits = hits[2].hits;
    const catPromise = catHits.map(async cat => {
      return {
        ...cat,
        deals: await getDealsByCat(cat.id)
      };
    });
    categories = await Promise.all(catPromise);
  }
  return json({
    categories,
    hotDeals: mainCatgories ? hits[1] : hits[1],
    deals: mainCatgories ? hits[0] : hits[0],
    page,
    tab,
    selectedTab,
    root,
    category: catObj
  }, {
    status: 200,
    headers: {
      "Cache-Control": "max-age=60, stale-while-revalidate=30, s-maxage=120"
    }
  });
}
export default function Index() {
  _s();
  const data = useLoaderData();
  // const { colorMode, toggleColorMode } = useColorMode();
  const hotDeals = data.hotDeals.hits.map(hit => parseHitToDealHit(hit));

  // console.log("data", data);

  let category, deals, estimatedTotalHits;
  if (data.category) {
    category = parseHitToCategory(data.category);
    deals = data.deals.hits.map(hit => parseHitToDealHit(hit));
    estimatedTotalHits = data.deals?.estimatedTotalHits ? data.deals?.estimatedTotalHits : 0;
  }
  return <>
      {data.category && category && <FrontpageHero heroImage={category.titleImage} header={<>
              <Navigation />
              <Center height={{
        base: "200px",
        md: "350"
      }}>
                <VStack>
                  {category.brandLogo && <Image src={category.brandLogo} objectFit={"contain"} bgColor={"white"} borderRadius={"md"} w={"100px"} h={"100px"}></Image>}
                  <Heading size={{
            base: "lg",
            md: "xl"
          }} textShadow={"0 4px 8px rgba(0,0,0,.2)"} textAlign={"center"} mx={10}>
                    {category.title}
                  </Heading>
                  {!data?.categories && <HStack>
                    {/* <Button
                      bg={"linear-gradient(135deg, #f72461, #c914ba)"}
                      leftIcon={<BsBookmark />}
                      size={{ base: "md", md: "lg" }}
                      color={useColorModeValue("gray.100", "gray.200")}
                      _hover={{
                        bgColor: useColorModeValue("pink.100", "pink.100"),
                        color: useColorModeValue("gray.800", "gray.800"),
                      }}
                     >
                      Folgen
                     </Button> */}
                    
                    <Tag size={{
              base: "md",
              md: "lg"
            }}>
                      {category.dealCount} Deals
                    </Tag>
                  </HStack>}
                </VStack>
              </Center>
            </>} sidebar={<Box>{hotDeals && <DealListSmall deals={hotDeals} />}</Box>} main={<VStack px="4">
              {deals && <>
                <DealList deals={deals} page={data.page + 1} root={data.root} totalHits={estimatedTotalHits} />
                {estimatedTotalHits && estimatedTotalHits > 25 && <Pagination key={data.root} currentPage={data.page + 1} limit={25} total={estimatedTotalHits || 0} root={data.root} />}
                </>}
              {data.categories && <VStack alignItems={"left"}>
                  {data.categories.map(item => <CategoryCard key={item.id} item={item} />)}
                </VStack>}
            </VStack>} />}
    </>;
}
_s(Index, "5thj+e1edPyRpKif1JmVRC6KArE=", false, function () {
  return [useLoaderData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;